<template>
  <div class="page-main" v-loading="loading">
    <el-form ref="emailForm" :model="info" :rules="emailRules" label-width="100px">
      <el-row>
        <el-col :span="12">
          <el-form-item prop="templateNo" label="模板编号" :error="errors.templateNo">
            <el-input v-model.trim="info.templateNo" maxlength="20"
                      @blur="handleTemplateNo(info.templateNo)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="templateName" label="模板名称">
            <el-input v-model.trim="info.templateName" maxlength="100"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="emailTitle" label="邮件主题">
            <el-input v-model.trim="info.emailTitle" maxlength="100"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="eventType" label="任务类型">
            <el-select v-model="info.eventType" @change="handleDispatchNodeChange(info.eventType)"
                       placeholder="请选择">
              <el-option
                  v-for="item in eventTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="variableDesc" label="变量说明">
            <el-input type="textarea" v-model="info.variableDesc" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="emailContent" label="邮件正文">
            <!--            <el-upload-->
            <!--                class="avatar-uploader"-->
            <!--                action=""-->
            <!--                accept="image/jpg, image/jpeg, image/png, image/gif"-->
            <!--                :http-request="upload"-->
            <!--                :before-upload="beforeUploadImg"-->
            <!--                :on-success="uploadSuccess"-->
            <!--                :on-error="uploadError"-->
            <!--                :show-file-list="false">-->
            <!--              <i class="el-icon-plus avatar-uploader-icon"></i>-->
            <!--            </el-upload>-->
            <quill-editor
                ref="myQuillEditor"
                v-model="info.emailContent"
                :options="editorOption"
                @blur="onEditorBlur($event)"
                @focus="onEditorFocus($event)"
                @change="onEditorChange($event)"
            >
            </quill-editor>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="templateNotes" label="模板备注">
            <el-input type="textarea" v-model.trim="info.templateNotes" maxlength="200"
                      style="height: 50px; max-height: 100px;"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="el-button-ext">
            <el-button @click="handleCancel">取消</el-button>
            <el-button v-loading="saveBtnLoading" @click="handleSaveContent" type="primary">保存
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {quillEditor} from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {checkUnique, getEmail, saveEmail} from '@/service/mail'
import {toolbarOptions} from '@/content/emailInfo'

export default {
  name: 'MailEdit',
  components: {quillEditor},
  props: {
    emailInfoData: {
      type: Object
    }
  },
  watch: {
    info: {
      handler(newName, oldName) {
        if (newName.emailContent != '') {
          this.$refs.emailForm.clearValidate('emailContent')
        }
      },
      immediate: true,
      deep: true // 开启深度监听，默认是false
    }
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    }
  },
  data() {
    return {
      errors: {
        templateNo: ''
      },
      emailRules: {
        templateNo: [
          {required: true, message: '请输入模板编号!'}
        ],
        templateName: [
          {required: true, message: '请输入模板名称!'}
        ],
        emailTitle: [
          {required: true, message: '请输入邮件主题!'}
        ],
        emailContent: [
          {required: true, message: '请输入邮件正文!'}
        ],
      },
      eventType: '',
      eventTypes: [],
      loading: false,
      info: {
        templateNo: '',
        templateName: '',
        emailTitle: '',
        variableDesc: '',
        eventType: '',
        templateNotes: '',
        emailContent: ''
      },
      saveBtnLoading: false,
      quillUpdateImg: false, // 根据图片上传状态来确定是否显示loading动画，刚开始是false,不显示
      editorOption: {
        theme: 'snow', // or 'bubble'
        placeholder: '请在这里输入...',
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              // 处理图片上传
              // image: function (value) {
              //   if (value) {
              //     // 触发input框选择图片文件
              //     document.querySelector('.avatar-uploader input').click()
              //   } else {
              //     this.quill.format('image', false)
              //   }
              // }
            }
          }
        }
      }
      // serverUrl: process.env.VUE_APP_BASE_API + '/user/asset/webuploader', // 这里写你要上传的图片服务器地址, 这里 process.env.VUE_APP_BASE_API 是我在环境变量中添加的，具体可以百度vue环境变量
      // imageBaseUrl: process.env.VUE_APP_BASE_API, // 这里写你要上传的图片服务器地址
      // header: {
      // token: sessionStorage.token
      // } // 有的图片服务器要求请求头需要有token
    }
  },
  created() {
    this.getDicts('sys-event-type').then(({data}) => this.eventTypes = data)
    this.getEmailTemplate()
  },
  methods: {
    async handleTemplateNo(val) {
      if (this.emailInfoData.id > 0) {
        // 修改时模板编号未改动不校验
        if (val == this.emailInfoData.templateNo) {
          return
        }
      }
      // 校验存在性
      if (val != '') {
        const {success, data} = await checkUnique(val)
        if (success) {
          this.errors.templateNo = ''
          if (!data.emailUnique) {
            this.$nextTick(() => {
              this.errors.templateNo = '已经存在的模板编号'
            })
          }
        }
      }
    },
    handleDispatchNodeChange(val) {
      let actions = []
      Object.keys(this.eventTypes).some((key) => {
        if (this.eventTypes[key].value == ('' + val)) {
          actions.push(this.eventTypes[key].remark)
          return true
        }
      })
      this.info.variableDesc = actions.join('')
    },
    async getEmailTemplate() {
      if (this.emailInfoData.id > 0) {
        let param = {
          id: this.emailInfoData.id,
          page: 1,
          size: 1
        }
        const {success, data} = await getEmail(param)
        if (success) {
          this.info = {...data.records.shift()}
        }
      }
    },
    async handleSaveContent() {
      if (this.errors.templateNo != '') {
        return
      }
      // 校验其他字段
      let fields = ['templateNo', 'templateName', 'emailTitle', 'emailContent']
      let fieldError = []
      this.$refs['emailForm'].validateField(fields, (errorMsg) => {
        if (errorMsg != '') {
          fieldError.push(errorMsg)
        }
      })
      if (fieldError.length > 0) return
      const {success} = await saveEmail(this.info)
      const message = this.emailInfoData.id > 0 ? '修改' : '新增'
      this.saveBtnLoading = false
      if (success) {
        this.toast(`${message}邮件模板信息成功`, 'success', () => this.handleCancel('success'))
      } else {
        this.toast(`${message}邮件模板信息失败`, 'error')
      }
    },
    // 点击取消的回调
    handleCancel(status) {
      this.$emit('handleCancelEditDialog', status)
    },
    // 重置表单
    resetForm() {
      this.$refs.emailForm.resetFields()
    },
    onEditorBlur() {// 失去焦点事件
      if (this.info.emailContent == '') {
        this.$refs.emailForm.validateField('emailContent')
      }
    },
    onEditorFocus() {// 获得焦点事件
    },
    onEditorChange() { // 内容改变事件
      this.$emit('input', this.info.emailContent)
    },
    // 富文本图片上传前
    // beforeUpload() {
    //   // 显示loading动画
    //   this.quillUpdateImg = true
    // },
    // uploadSuccess(res, file) {
    // res为图片服务器返回的数据
    // 获取富文本组件实例
    // const quill = this.$refs.myQuillEditor.quill
    // 如果上传成功
    //   if (res.code == 1) {
    //     this.$message.success('图片上传成功')
    //     // 获取光标所在位置
    //     const length = quill.getSelection().index
    //     // 插入图片  res.url为服务器返回的图片地址，需要拼接图片服务根地址
    //     quill.insertEmbed(length, 'image', res.data.url)
    //     // 调整光标到最后
    //     quill.setSelection(length + 1)
    //   } else {
    //     this.$message.warning('图片插入失败')
    //   }
    //   // loading动画消失
    //   this.quillUpdateImg = false
    // },
    // 富文本图片上传失败
    // uploadError() {
    //   // loading动画消失
    //   this.quillUpdateImg = false
    //   this.$message.warning('图片插入失败')
    // }
  }
}
</script>

<style scoped lang="scss">
.page-main {
  padding: 0 24px;
  height: 600px;
  overflow: auto;

  ::v-deep .el-form-item {
    margin-bottom: 22px;
  }

  ::v-deep .el-form-item__error {
    top: 100%;
  }

  .emailContent {
    margin-bottom: 20px;
  }

  ::v-deep .el-input {
    width: 100%;
  }

  ::v-deep .el-form-item__label, ::v-deep .el-form-item__content {
    height: initial;
    line-height: initial;

    // 设置编辑器高度
    .quill-editor .ql-container {
      height: 300px;
    }

    // 设置下拉框宽度
    .el-select {
      width: 100%;
    }
  }

  .el-button-ext {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
