import {get, post, download, del, put} from './index'

/**
 * 保存邮件模板
 * @param data
 * @returns {Promise<unknown>}
 */
export const saveEmail = data => {
    return post('/email/saveTemplate', data).then(res => res)
}

/**
 * 获取邮件模板
 * @param data
 * @returns {Promise<unknown>}
 */
export const getEmail = data => {
    return post('/email/getTemplate', data).then(res => res)
}

/**
 * 删除邮件模板
 * @param data
 * @returns {Promise<unknown>}
 */
export const deleteById = data => {
    return post(`/email/deleteTemplate/${data}`).then(res => res)
}

/**
 * 查询邮件模板是否在使用
 * @param data
 * @returns {Promise<unknown>}
 */
export const queryStatus = data => {
    return post('/email/queryStatus', data).then(res => res)
}

/**
 * 判断邮件模板编号是否唯一
 * @param data
 * @returns {Promise<unknown>}
 */
export const checkUnique = data => {
    return post(`/email/checkUnique/${data}`).then(res => res)
}
